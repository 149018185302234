import ContentBlocks from 'app/components/content-blocks/index';
import Grid from 'app/components/partials/grid';
import Hero from 'app/components/partials/hero';
import Map from 'app/components/partials/map';
import NewsletterStrip from 'app/components/partials/newsletter-strip.container.js';
import NotificationPageSpecific from 'app/components/partials/notification-page-specific.container';
import PropTypes from 'prop-types';
import React from 'react';
import Tabs from 'app/components/partials/tabs';

const VENUEPAGE_TABS = [
    {
        title: 'Visitor Information',
        value: 'visitor-information'
    },
    {
        title: 'About the Venue',
        value: 'about-the-venue'
    },
    {
        title: 'Current Shows',
        value: 'current-shows'
    },
    {
        title: 'Hire the Venue',
        value: 'hire-the-venue'
    }
];

const Venue = ({ data }) => {
    const { contentBlocks, showList, venue } = data;

    const renderVisitorInformationTab = () => (
        <section className="content-section">
            <div className="constrain-width medium || centered">
                <h1 className="heading bordered">Visitor Information</h1>
                <div className="description || rich-editor-content" dangerouslySetInnerHTML={{ __html: venue.openHours }}></div>
            </div>
            <div className="subsection || constrain-width">
                <Map lat={venue.location.geo.lat} lng={venue.location.geo.lng} />
            </div>
            {contentBlocks.info && contentBlocks.info.length !== 0 && <ContentBlocks data={contentBlocks.info} pageTitle={venue.name} />}
        </section>
    );

    const renderAboutVenueTab = () => (
        <section className="content-section">
            <div className="constrain-width medium || centered">
                <h1 className="heading bordered">About the Venue</h1>
                <div className="description || rich-editor-content" dangerouslySetInnerHTML={{ __html: venue.description }}></div>
                {venue.tourLink && <a className="button primary" rel="noreferrer" href={venue.tourLink} target="_blank">Take Virtual Tour</a>}
            </div>
            {contentBlocks.about && contentBlocks.about.length !== 0 && <ContentBlocks data={contentBlocks.about} pageTitle={venue.name} />}
        </section>
    );

    const renderHireVenueTab = () => (
        <section className="content-section">
            <div className="constrain-width medium || centered">
                <h1 className="heading bordered">Hire this Venue</h1>
            </div>
            {contentBlocks.hire && contentBlocks.hire.length !== 0 && <ContentBlocks data={contentBlocks.hire} pageTitle={venue.name} />}
        </section>
    );

    const renderCurrentShowsTab = () => (
        <section className="content-section">
            <div className="constrain-width medium || centered">
                <h1 className="heading bordered">Current Shows</h1>
            </div>
            <Grid data={showList} heading={`On at ${venue.name}`}/>
        </section>
    );

    const renderVenueTabsPanel = (tabItem) => {
        if (tabItem === VENUEPAGE_TABS[0]) {
            return renderVisitorInformationTab();
        }

        if (tabItem === VENUEPAGE_TABS[1]) {
            return renderAboutVenueTab();
        }

        if (tabItem === VENUEPAGE_TABS[2]) {
            return renderCurrentShowsTab();
        }

        if (tabItem === VENUEPAGE_TABS[3]) {
            return renderHireVenueTab();
        }
    };

    const FTLTERED_VENUEPAGE_TABS = [...VENUEPAGE_TABS];
    // Remove hire the venue if there is no content
    if (!contentBlocks.hire || contentBlocks.hire.length === 0) {
        const indexToRemove = VENUEPAGE_TABS.findIndex((item) => item.value === 'hire-the-venue');
        if (indexToRemove !== -1) {
            FTLTERED_VENUEPAGE_TABS.splice(indexToRemove, 1);
        }
    }

    // Remove the current shows if there is no current shows
    if (!showList || showList.length === 0) {
        const indexToRemove = VENUEPAGE_TABS.findIndex((item) => item.value === 'current-shows');
        if (indexToRemove !== -1) {
            FTLTERED_VENUEPAGE_TABS.splice(indexToRemove, 1);
        }
    }

    return (
        <div className="content-page || venue-page">
            <Hero {...venue.hero}/>
            <NotificationPageSpecific type="venue" slug={venue.slug} />
            <Tabs key={venue.slug} tabsList={FTLTERED_VENUEPAGE_TABS} renderTabsPanel={renderVenueTabsPanel} hashChange={true} />
            <NewsletterStrip pageTitle={venue.name} />
        </div>
    );
};

Venue.propTypes = {
    data: PropTypes.object.isRequired
};

export default Venue;
