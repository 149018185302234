import Accordion from 'app/components/partials/accordion/index';
import ContentBlocks from 'app/components/content-blocks/index';
import Hero from 'app/components/partials/hero';
import Icon from 'app/components/partials/icon';
import { Link } from 'react-router-dom';
import NewsletterStrip from 'app/components/partials/newsletter-strip.container.js';
import NotificationPageSpecific from 'app/components/partials/notification-page-specific.container';
import Performance from 'app/components/partials/performance';
import PerformancesCalendarMonth from 'app/components/partials/performances-calendar-month';
import PropTypes from 'prop-types';
import Tabs from 'app/components/partials/tabs';
import TextContent from 'app/components/content-blocks/text-content';
import VenueBlocks from 'app/components/partials/venue-blocks';
import React, { useState } from 'react';

const SHOWPAGE_TABS = [
    {
        title: 'About the Event',
        value: 'about-the-event'
    },
    {
        title: 'Tickets & Times',
        value: 'tickets-times'
    }
];

const Show = ({ data }) => {
    const { contentBlocks, show } = data;
    const [isCalendarView, setIsCalendarView] = useState(true);

    const getInformationItems = () => {
        const accessibility = show.accessibility;
        const information = {};
        const output = [];
        const specifications = show.specifications;
        let index = 0;

        Object.assign(information, specifications);

        for (const item in information) {
            if (information[item]) {
                output.push(<li className={`item ${item}`} key={index}>{information[item].toString()}</li>);
                index++;
            }
        }

        for (const item in accessibility) {
            if (accessibility[item]) {
                output.push(
                    <li className={`item ${item}`} key={index}>
                        { item === 'virtual-tours' ?
                            <a href="https://guidedtours.property3d.co.nz/tour/WyergVczDD-JxgHx4qGvw/Accessibility_Virtual_Tours" target="_blank" rel="noreferrer">{accessibility[item].toString()}</a>
                            :
                            <Link to="/visitor-info/accessibility">{accessibility[item].toString()}</Link>
                        }
                    </li>
                );
                index++;
            }
        }

        return output;
    };

    const getAdditionalInformation = () => {
        if (show.additionalInformation) {
            return (
                <div
                    className="additional-information || rich-editor-content"
                    dangerouslySetInnerHTML={{ __html: show.additionalInformation }}
                >
                </div>
            );
        }
    };

    const getInformation = () => {
        if (getInformationItems().length > 0 || getAdditionalInformation()) {
            return (
                <aside className="content-aside no-top-padding bordered">
                    <h3 className="key-information">Key Information</h3>
                    <ul className="specifications">
                        {getInformationItems()}
                    </ul>
                    {getAdditionalInformation()}
                </aside>
            );
        }

        return null;
    };

    const toggleView = (value) => {
        setIsCalendarView(value);
    };

    const renderToggleViewButtonGroup = () => (
        <div className="view-button-group">
            <button className={`button secondary ${!isCalendarView && 'is-active'}`} type="button" onClick={() => toggleView(false)}>
                <Icon name="menu" />
            </button>
            <button className={`button secondary ${isCalendarView && 'is-active'}`} type="button" onClick={() => toggleView(true)}>
                <Icon name="calendar" />
            </button>
        </div>
    );

    const renderPerformancesTabPanel = (tab) => {
        const { performanceGroups } = show;

        if (!show.calendarView || !isCalendarView) {
            return (
                <div className="timetable-list">
                    <div className="constrain-width">
                        {performanceGroups[tab.value].performances.map((performance, index) => !performance.isPast && <Performance key={index} performance={performance} isFree={show.isFree} />)}
                    </div>
                </div>
            );
        }

        return (
            <div className="timetable-list">
                <div className="performances-content-calendar || constrain-width">
                    <PerformancesCalendarMonth performanceGroup={performanceGroups[tab.value]} />
                </div>
                <div className="performances-content-menu || constrain-width">
                    {performanceGroups[tab.value].performances.map((performance, index) => !performance.isPast && <Performance key={index} performance={performance} isFree={show.isFree} />)}
                </div>
            </div>
        );
    };

    const hasPerformances = (performances) => performances.find((performance) => !performance.isPast);

    const renderPerformances = () => {
        const { performanceGroups } = show;

        const performanceTabsList = Object.keys(performanceGroups).reduce((acc, groupKey) => {
            if (hasPerformances(performanceGroups[groupKey].performances)) {
                acc.push({
                    title: groupKey,
                    value: groupKey
                });
            }

            return acc;
        }, []);

        return (
            <React.Fragment>
                <h2 className="constrain-width">Upcoming Performances</h2>
                <hr />
                {show.calendarView && renderToggleViewButtonGroup()}
                {performanceTabsList && performanceTabsList.length !== 0 && <Tabs key={`${show.slug}-performance`} extraClass="performance-tabs" tabsList={performanceTabsList} renderTabsPanel={renderPerformancesTabPanel}/>}
            </React.Fragment>
        );
    };

    const renderEventTab = () => (
        <React.Fragment>
            <header className="page-header || constrain-width">
                {show.presenter && <p className="details">{show.presenter}</p>}
                {show.name && <h1 className="bordered">{show.name}</h1>}
                {show.subtitle && <h5 className="subtitle || bordered">{show.subtitle}</h5>}
                {show.company && <p className="credit">{show.company}</p>}
                {show.artisticCredit && <p className="credit">{show.artisticCredit}</p>}
            </header>
            <div className="content-blocks">
                <TextContent content={show.description}>
                    {getInformation()}
                </TextContent>
            </div>
            <ContentBlocks data={contentBlocks} pageTitle={show.name} />
            {show.venues && show.venues.length !== 0 && <VenueBlocks venues={show.venues} />}
        </React.Fragment>
    );

    const renderTicketsTab = () => (
        <React.Fragment>
            { show.pricingBlock && show.pricingBlock.length !== 0 && (
                <section className="content-section || constrain-width || text-content-block">
                    <div className="content-primary">
                        <h2>Ticket Pricing</h2>
                        <Accordion items={show.pricingBlock} type="pricing-block" defaultActiveIndex={0} />
                    </div>
                    {show.seatingPlanChart && (
                        <aside className="content-aside">
                            <img className="contain" src="" alt="Seating Plan"/>
                        </aside>
                    )}
                </section>
            )}
            <section className="content-section || performance-timetable">
                {show.performances && show.performances.length !== 0 && renderPerformances()}
            </section>
        </React.Fragment>
    );

    const renderShowPageTabPanel = (tabItem) => {
        if (tabItem === SHOWPAGE_TABS[0]) {
            return renderEventTab();
        }

        if (tabItem === SHOWPAGE_TABS[1]) {
            return renderTicketsTab();
        }
    };

    const performancesJsonLd = () => (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: `
            ${JSON.stringify(show.performancesStructuredData)}
        ` }} />
    );

    const shareData = {
        name: show.name,
        media: data.openGraph.image,
        subject: 'I\'d love to see this show, thought you might too?',
        text: 'Check out the following event on Auckland Live website:',
        url: show.url
    };

    return (
        <div className="content-page || show-page">
            {show.hero.image && <Hero {...show.hero} hasButtonTickets={true} venuesExist={Boolean(show.venues.length)} />}
            <NotificationPageSpecific type="show" slug={show.slug} />
            <Tabs key={show.slug} hasShare={true} shareData={shareData} tabsList={SHOWPAGE_TABS} hashChange={true} renderTabsPanel={renderShowPageTabPanel} />
            {performancesJsonLd()}
            <NewsletterStrip pageTitle={show.name} />
        </div>
    );
};

Show.propTypes = {
    data: PropTypes.object.isRequired
};

export default Show;
