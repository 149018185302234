import Notification from './notification';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const NotificationPageSpecific = ({ notifications = [], slug, type }) => {
    const filteredNotifications = useMemo(() => {
        return notifications.filter((item) => {
            if (!item.pagesVisibleOn || !Array.isArray(item.pagesVisibleOn)) {
                return false;
            }

            return item.pagesVisibleOn.some((page) =>
                page && page.type === type && page.slug === slug
            );
        });
    }, [notifications, slug, type]);

    return (
        <>
            {filteredNotifications.map((item, index) => (
                <Notification
                    key={`notification-${item.id || index}`}
                    notification={item}
                    closable={false}
                    isPageBased
                />
            ))}
        </>
    );
};

NotificationPageSpecific.propTypes = {
    notifications: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            pagesVisibleOn: PropTypes.arrayOf(
                PropTypes.shape({
                    type: PropTypes.string.isRequired,
                    slug: PropTypes.string.isRequired
                })
            ).isRequired
        })
    ).isRequired,
    slug: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
};

export default NotificationPageSpecific;
